











































import Vue from 'vue'
import TheButton from '@/components/TheButton.vue'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle'

export default Vue.extend({
    name: 'ModalDepartmentEdit',

    components: { TheButton },

    props: {
        company: Object,
        isAdd: Boolean,
        department: Object,
    },

    data: () => ({
        isLoading: false,
        faQuestionCircle,
    }),

    methods: {
        showSuccessToast() {
            this.$bvToast.toast(`Department data successfully updated`, {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false,
            })

            this.$emit('completed')
        },

        showErrorToast() {
            this.$bvToast.toast(`Not enough privileges to perform that action`, {
                title: 'Forbidden',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false,
            })
        },

        addOrUpdateDepartment() {
            this.isLoading = true
            this.department.companyId = this.company.id

            if (this.isAdd) {
                this.$store.state.backend
                    .post('/admin/create-project', this.department)
                    .then(() => this.showSuccessToast())
                    .catch((err: any) => this.showErrorToast())
                    .finally(() => (this.isLoading = false))
            } else {
                this.$store.state.backend
                    .post('/admin/update-project', this.department)
                    .then(() => this.showSuccessToast())
                    .catch((err: any) => this.showErrorToast())
                    .finally(() => (this.isLoading = false))
            }
        },
    },
})

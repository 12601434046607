<template lang="pug">
  .card
    .card-body
      div
        p Select company to show departments of:
        div.row
          .col-6
            multiselect(
              v-model="selectedCompany",
              deselect-label="Unselect",
              track-by="name",
              label="name",
              placeholder="Select company",
              :options="companies",
              :searchable="true",
              :allow-empty="true"
              :preselect-first="companies.length === 1"
            )
          .col-6
            b-button.ml-2(@click="showAddDepartment" :disabled="!selectedCompany" variant="success") Add department
        div.row
          .col-12
            ModalDepartmentEdit(v-show ="selectedCompany && IsAddDepartmentModal" 
            :department="department" 
            :company="selectedCompany" 
            @completed="fetchDepartmentsFromCompany"
            :isAdd="true")

        b-table.mt-2(
          :items="departments"
          :fields="fields"
          )
          template(v-slot:cell(active)="data")
            span(:style="{ color: data.item.active ? 'green' : 'red'}")
              | {{data.item.active ? 'Active' : 'Inactive'}}
          
          template(v-slot:cell(action)="row")
            b-button.mr-2(@click="row.toggleDetails" variant="outline-secondary" size="sm") Edit
            b-button(
              variant="outline-danger"
              size="sm"
              @click="toggleActivation($event.target, row.item)"
            )
              | {{row.item.active ? 'Deactivate' : 'Activate' }}

          template(v-slot:row-details="row")
            ModalDepartmentEdit(:department="row.item" :company="selectedCompany" :isAdd="false" @completed="fetchDepartmentsFromCompany" :isLoading="isLoading")
</template>
<script>
import Multiselect from 'vue-multiselect'
import TheButton from '@/components/TheButton'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { ModalDepartmentEdit } from './components'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'Departments',

    components: {
        Multiselect,
        TheButton,
        ModalDepartmentEdit,
    },
    beforeMount() {
        this.fetchCompanies()
    },
    computed: {
        ...mapState(['userAccount']),
        tagIcon: () => faTag,
        questionIcon: () => faQuestionCircle,
    },

    watch: {
        companies() {
            if (this.selectedCompany) this.fetchDepartmentsFromCompany()
        },

        selectedCompany(val) {
            val ? this.fetchDepartmentsFromCompany() : (this.departments = [])
        },
        userAccount: {
            handler(userAccount) {
                if (!!userAccount) {
                    this.fetchCompanies()
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            companies: [],
            departments: [],
            department: { name: '' },
            fields: [
                { key: 'name', label: 'Department' },
                { key: 'active_jobs' },
                { key: 'active', label: 'Status' },
                { key: 'action', thStyle: { width: '200px' } },
            ],

            isLoading: false,
            selectedCompany: null,
            IsAddDepartmentModal: false,
        }
    },

    methods: {
        fetchDepartmentsFromCompany() {
            return this.$store.state.backend
                .get(`/jobBuilder/project/all/${this.selectedCompany.id}`)
                .then((ret) => (this.departments = ret.data))
                .catch((error) => alert(error))
        },

        toggleActivation(button, project) {
            button.disabled = true

            this.$store.state.backend
                .post('/admin/update-activation/project', {
                    id: project.id,
                    active: !project.active,
                    company_id: this.selectedCompany.id,
                })
                .then((res) => {
                    if (res.data.info) {
                        alert(res.data.info)
                    } else {
                        this.$emit('fetch-companies')
                        return this.fetchDepartmentsFromCompany()
                    }
                })
                .catch((err) => this.showErrorToast())
                .finally(() => (button.disabled = false))
        },
        fetchCompanies(resolve) {
            this.$store.state.backend
                .get('/jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
                .finally(() => {
                    if (resolve) resolve('resolved')
                })
        },
        showAddDepartment() {
            this.IsAddDepartmentModal = !this.IsAddDepartmentModal
        },
    },
}
</script>
<style lang="sass" scoped>
label
  font-size: 14px
  font-weight: bold
</style>

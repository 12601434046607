<template lang="pug">
  b-button(
    :size="size"
    :variant="variant"
    :type="type"
    :disabled="isLoading"
    @click="$emit('click')"
  )
    slot
    font-awesome-icon.ml-2(
      v-if="isLoading"
      :icon='cogIcon'
      spin=''
    )
</template>
<script>
import { faCog } from '@fortawesome/free-solid-svg-icons'

/**
 * @deprecated use BaseBtn instead
 */
export default {
    props: {
        isDisabled: {
            type: Boolean,
            default: () => false,
        },
        isLoading: Boolean,
        size: {
            type: String,
            default: () => 'sm',
        },
        type: {
            type: String,
            default: () => 'button',
        },
        variant: String,
    },

    computed: {
        cogIcon: () => faCog,
    },
}
</script>
